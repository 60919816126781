.containerDetail {
    table.table {
        .table-header {
            font-weight: 600;

            tr:first-child th {
                width: max-content;
            }
        }

        .rewardPic {
            .firstImage {
                width: 70px;
                border-radius: 50%;
                height: 70px;
                object-fit: unset;
                border: 1px solid #00a453;
                padding: 0;
            }
        }
    }

    .RewardInfoBtn {
        border-color: #5ec096 !important;
        background-color: #5ec096 !important;
        text-transform: uppercase;
        font-family: sans-serif;
        font-size: 15px;
    }
}
.Reward_heading {
    h3 {
        margin-bottom: 25px;
        font-size: 32px;
        color: #252323;
        text-transform: uppercase;
        font-weight: 400;
    }
}
.table__pagination__container {
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination__select {
        padding: 5px;
        align-items: center;
        color: black;

        .dropdwon_Option {
            margin-top: 1px;
            font: 10px;
            height: 50px;
        }
    }
}

@media (max-width: 768px) {
    .containerDetail {
        table.table {
            .table-header {
                font-weight: 600;

                tr:first-child th {
                    width: 8%;
                    white-space: nowrap;
                    font-size: 12px;
                }
            }

            .rewardPic {
                .firstImage {
                    // width: 100%;
                    // padding: 10px;
                    width: 80%;
                    border-radius: 50%;
                    padding: 10px;
                    aspect-ratio: 1/1;
                }
            }
        }

        .RewardInfoBtn {
            font-size: 12px;
            white-space: nowrap;
        }
    }
}
