.headingchart {
    color: #484747;
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.text-left {
    text-align: left;
}

.containerDetail {
    margin-bottom: 5rem;

    table {
        border: 1px solid #e4e5e7;

        tbody {
            tr:nth-child(odd) {
                background-color: #f5f5f5;
            }

            tr:hover {
                background-color: #f5f5f5;
            }
        }

        th {
            font-weight: bold;
            text-transform: capitalize;
        }

        td {
            color: #666666;
        }
    }
}

.profileSec {
    table {
        thead {
            th {
                border-left: unset !important;
                border: 1px solid #ccc !important;
            }
        }

        tr {
            td {
                border: 1px solid #ccc !important;
                border-left: 0;
            }
        }
    }

    margin-bottom: 100px;
}

.table>thead>tr.info>th {
    background-color: #5ec096;
    text-align: center;
    font-weight: bold;
    color: #ffff;
    font-size: 15px;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    text-align: center;
    border-top: 0px;
    border-left: 2px solid #bae2c3;
    border-right: 1px;
    border-bottom: 1px;
    vertical-align: middle;
}

//simple map

.map-marker {
    position: absolute;
    transform: translate(-50%, -80%);
}

.map-marker-img {
    height: 50px;
    width: 50px;
}

//save button

.save-btn {
    font-size: 20px;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 3px 6px;
    width: 10%;
    border: none;
    background: #5ec096;
    margin-bottom: 3.5em;
    border-radius: 6px;
}

.editInfoSec {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .containerDetail {
        margin-bottom: 5rem;
    }

    .editInfoSec {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.mandatory-field {
    color: red;
    font-style: bold;
    margin-right: 2px;
}

.custom-Loader {
    min-height: 100vh;
}