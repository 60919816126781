.Menuheader {
    background: #5ec096;
    height: 87px;
}

.menunavlink {
    background: #fff;
    /* border-radius: 2px; */
    margin-bottom: 4px;
}

.menunavimg {
    height: 62px;
    width: 190px;
    margin: 1px;
    object-fit: contain;
    padding: 9px 6px;
}

.vendorName {
    cursor: pointer;
    .navTitle:hover {
        background-color: #315657;
    }
}

.navbarmain {
    background-color: #5ec096 !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.linkwhite {
    color: white !important;
    font-weight: bold;
}
.activelink {
    background-color: #666666;
    border-radius: 5px;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 11;
    display: none;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.dropdown:hover > .dropdown-menu {
    overflow: visible;
    list-style: none;
    display: block;
    z-index: 9990;
}

.dropdown-menu li a {
    float: none;
    border-radius: 5px;
    line-height: initial;
    padding: 5px;
    text-decoration: none !important;
    font-weight: bold;
    color: #444;
}

.drop-menu-li:hover a {
    color: #fff;
}

.MainHeader {
    .navbar-toggler:focus {
        box-shadow: none;
    }

    .hamburger-menu {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 2rem;
        width: 2rem;
        cursor: pointer;
    }

    .bar-top,
    // .bar-middle,
    .bar-bottom {
        height: 5px;
        background: white;
        border-radius: 5px;
        margin: 3px 0;
        transform-origin: left;
        transition: all 0.5s;
    }
    .bar-middle {
        font-size: 12px;
        font-weight: 700;
        transform-origin: left;
        transition: all 0.5s;
        background-color: #fff !important;
        position: relative;
        top: -1.5px;
    }

    .navbar-toggler[aria-expanded='true'] .hamburger-menu:hover .bar-top {
        transform: rotate(45deg);
    }

    .navbar-toggler[aria-expanded='true'] .hamburger-menu:hover .bar-middle {
        transform: translateX(1rem);
        opacity: 0;
    }

    .navbar-toggler[aria-expanded='true'] .hamburger-menu:hover .bar-bottom {
        transform: rotate(-45deg);
    }

    // .navbar-nav {
    //     align-items: center;
    // }

    .lidiv {
        padding: 14px;

        &:hover {
            background-color: #315657;
        }
    }

    .activelink {
        background-color: #315657;
        border-radius: 0;

        a.nav-link.linkwhite.active {
            padding: 8px 5px;
        }
    }
}

@media (max-width: 768px) {
    .navbarmain {
        background-color: #fff !important;
        box-shadow: rgb(115 115 115 / 19%) 0px 10px 20px,
            rgb(182 182 182 / 11%) 0px 5px 5px;
    }
    .MainHeader .bar-top,
    .MainHeader .bar-middle,
    .MainHeader .bar-bottom {
        background-color: #484747;
    }
    .lidiv {
        padding: 14px 25px !important;
        a {
            border-bottom: 1px solid #454545;
        }
        button.place-order-button.nav-link {
            border-bottom: 1px solid #454545 !important;
            border-radius: 0;
            width: -webkit-fill-available;
            text-align: inherit;
        }
    }
    .navbar-collapse {
        background-color: #333333;
    }
    .lidiv.activelink {
        a {
            border-bottom: 0;
        }
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 5px;
    }
}
