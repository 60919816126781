//start place order scss

.jumbotron {
    display: flex;
    height: 40vh;
    color: #5ec096;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
}

.NodataFound {
    font-weight: 600;
}

.place-order-button {
    border: none !important;
    background: transparent !important;
    color: white !important;
    font-weight: bold;
}

.place-order-submitbtn {
    font-size: 20px;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    transition: 0.1s all;
    -webkit-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -o-transition: 0.1s all;
    cursor: pointer;
    outline: none;
    padding: 3px 6px;
    width: 100%;
    border: none;
    background: #82c434;
    margin-bottom: 0.5em;
    border-radius: 6px;
}

.rewardPic {
    .firstImage {
        width: 100%;
        padding: 10px;
    }
}

.infoContainer {
    margin-top: 25px;

    .containerDetail {
        table {
            tr,
            th,
            td {
                border: 1px solid #ccc;
            }

            th {
                border-bottom: 1px solid #ccc !important;
                color: #666666;
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 768px) {
    .First_Row {
        display: block;

        .Total_Pending_Details {
            padding-right: 0%;
        }
    }

    .order-info {
        font-size: smaller;
    }

    .table__pagination__container {
        // margin-bottom: 7rem;
        // flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px;
        margin-bottom: 7rem;

        ul {
            margin: 0;

            li {
                margin: 0;
                padding: 0;

                a {
                    padding: 2px 12px;
                }
            }
        }

        .pagination__select {
            margin-top: 10px;
        }
    }
}

.First_Row {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .Claims_Heading {
        padding: 20px 15px;
        padding-bottom: 0;

        h3 {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 32px;
        }
    }

    .Total_Pending_Details {
        padding: 25px 17px;
        padding-bottom: 0;
        p {
            font-size: 18px;
            text-transform: capitalize;
            font-weight: 400;
            color: #000;
            font-family: 'Poppins', sans-serif;
        }
    }
}

.table__pagination__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 7rem;

    .pagination__select {
        margin-left: 20px;
        padding: 9px;
        border: 1px solid #ccc;
        border-radius: 3px;

        &:focus-within {
            outline: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            border-radius: 3px;
            margin: 0 5px;

            a {
                display: block;
                padding: 8px 16px;
                text-decoration: none;
                color: #000;
                border: 1px solid #ccc;
                border-radius: 3px;

                &:hover {
                    background-color: #ccc;
                }
            }

            &.active {
                a {
                    background-color: #34c299;
                    color: #fff;
                    border-color: #34c299;

                    &:hover {
                        background-color: #34c299;
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// end place order scss

// Dashboard start scss
.dashboard-card {
    color: white;
    background-color: #5ec096;
    border-radius: 9px;
    border-color: #5ec096 !important;
    border-width: 3px;
    margin-bottom: 15px;
}

.dashboard-body {
    background: white !important;
    border-radius: 0 0 6px 6px;
}

.dashboard-title {
    color: #000;
    font-weight: bold;
}

.dashboard-icon {
    font-size: 50px;
}

.logo-crop-75 {
    width: 64px;
}

.dashboard-heading {
    font-size: 16px !important;
    text-align: center;
}

.welcome_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome_heading {
    color: #484747;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: left;
    margin-left: 3px;
    text-transform: uppercase;
    margin: 0;
    padding: 0px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
}

.welcome_logo img {
    width: 64px;
    margin: 0 10px;
}

.welcome_heading .text_color {
    color: #5ec096;
    font-size: 30px;
    font-weight: bold;
    display: block;
}

//Dashboard end scss

//profile start scss
.profile-btn {
    display: inline-block;
    font-size: 11px;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 6px 6px;
    width: 100%;
    border: none;
    background: #5ec096;
    margin-bottom: 0.5em;
    border-radius: 6px;
}

.edit-information,
.change-password {
    width: max-content;
    font-size: 12px;
    font-weight: 600;

    i {
        padding-right: 3px;
    }
}

.change-password-changebtn {
    font-size: 20px;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 3px 6px;
    width: 100%;
    border: none;
    background: #5ec096;
    margin-bottom: 0.5em;
    border-radius: 6px;
}

.heading-text-color {
    color: #5ec096;
}

.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}

.modalHeader {
    background-color: #e9fbe9;

    .modaltitle {
        margin-bottom: 0;
        font-weight: 700;
        font-family: sans-serif;
        line-height: 1.5;
    }
}

.account-settings .user-profile .user-img {
    margin: 0 0 1rem 0;
}

.account-settings .user-profile .user-img img {
    width: 95px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}

.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9fa8b9;
}

.account-settings .about {
    margin: 2rem 0 0 0;
    text-align: center;
}

.account-settings .about h5 {
    margin: 0 0 15px 0;
    color: #5ec096;
}

.account-settings .about p {
    font-size: 0.825rem;
}

.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 0.825rem;
    background: #ffffff;
    color: #2e323c;
}

.user-card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}

.dashboardinfomodal {
    border-color: #5ec096 !important;
    background-color: #5ec096 !important;
    text-transform: uppercase;
    font-family: sans-serif;
    font-size: 15px;
    width: max-content;
}

@media (max-width: 768px) {
    .First_Row {
        flex-direction: column;

        .Claims_Heading {
            padding: 0px 17px;
        }

        .Total_Pending_Details {
            padding: 10px 17px;
            p {
                margin: 0;
            }
        }
    }

    .order-info {
        font-size: smaller;
    }

    .table__pagination__container {
        // margin-bottom: 7rem;
        // flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px;
        margin-bottom: 7rem;

        ul {
            margin: 0;

            li {
                margin: 0;
                padding: 0;

                a {
                    padding: 2px 12px;
                }
            }
        }

        .pagination__select {
            margin-top: 10px;
        }
    }
}

@media (max-width: 768px) {
    .Welcome_Heading {
        padding-top: 17px;
    }
    .orderCard {
        min-height: 105px;
    }
}
