.table-header {
    background-color: #5ec096;
    text-align: center;
    font-weight: bold;
    color: #ffff;
    font-size: 17px;
}

.details {
    font-family: sans-serif;
    padding-top: 5px;
    font-weight: 700;
}

.backBtn {
    color: #fff;
    background-color: #5ec096;
    border-color: #5ec096;
}

.cursor-pointer {
    cursor: auto !important;
    padding: 0.375rem 1.75rem !important;
}

.NoDataFound {
    font-weight: 600;
}

.orderDetails tr:nth-child(odd) {
    background-color: #f5f5f5;
    border-color: white;
}

.orders {
    font-size: 32px;
    margin-bottom: 45px;
}

.order-info {
    border-color: #5ec096 !important;
    background-color: #5ec096 !important;
    text-transform: uppercase;
    font-family: sans-serif;
    font-size: 15px;

    i {
        padding-right: 5px;
    }
}

.order_summary_boxshadow {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px #ccc;
    margin-top: 25px;
}

.toporderbox {
    border: 2px solid #5ec096;

    h3 {
        background-color: #5ec096;
        color: #ffffff;
        font-size: 22px;
        font-weight: 600;
        padding: 10px 0;
        margin: 0;
    }

    .table>tbody>tr>td {
        border-left: 1px solid #5ec096;
    }
}

.toporderbox table {
    margin: 0px;
}

.table_format>thead>tr>th,
.table_format>tbody>tr>th,
.table_format>tfoot>tr>th,
.table_format>thead>tr>td,
.table_format>tbody>tr>td,
.table_format>tfoot>tr>td {
    text-align: left;
    padding: 12px;
    border-top: 0px;
    border-right: 1px;
    border-bottom: 1px;
}

.table-bordered {
    border: 1px solid #e4e5e7;
    width: 100%;
    margin-top: 30px;
}

.table_border td {
    color: #666666;
}

.table_border tr:nth-child(odd) {
    background: white;
}

.table_border tr:nth-child(even) {
    background: #e9fbe9;
}

// order box scss
.boxQuantity {
    height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}

.corporate_order_box {
    color: #000;
    border: 1px solid #ccc;
}

.ordinfo b {
    display: block;
    font-weight: bold;
    font-size: 14px;
}

.ordinfo {
    padding: 2.9px 0;
    word-wrap: break-word;
}

.corporate_order_box .ordinfo p {
    margin-bottom: 0;
}

.firstborderbox,
.centerborderbox,
.lastborderbox {
    padding-top: 15px;
    padding-bottom: 15px;
}

.firstborderbox img {
    width: 64px;
    padding-left: 0;
    margin-left: -8px;
}

.centerborderbox {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.ordinfo {
    padding: 2.9px 0;
    word-wrap: break-word;
}

.orderboxdetails img {
    width: 50px;
}

.orderboxdetails {
    font-size: 13px;
    color: #fff;
    text-align: center;

    .boxHeading {
        background: #5ec096;

        .BoxDetail {
            font-weight: 600;
            font-family: sans-serif;
            font-size: 22px;
            padding: 10px 0;
        }
    }
}

.orderHistorySec {
    margin-bottom: 13rem !important;
    margin-top: 15px;
}

.orderHeading {
    display: flex;
    align-items: baseline;

    p {
        position: relative;
        float: left;
        font-family: inherit;
        padding-bottom: 0px;
        margin-bottom: 0px;
        font-size: 30px;
        color: #252323;
        text-transform: uppercase;
        margin-left: 10px;
    }

    .backIcon {
        color: #5ec096;
        cursor: pointer;
        font-size: 23px;
    }
}

.invoiceDownload {
    cursor: pointer;
}

// ordered button
.orderbtnbg {
    background: #333dff !important;
    border-color: #333dff !important;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
}

.inprocessbtnbg {
    background: #f5977d !important;
    border-color: #f5977d !important;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
    color: white !important;
}

.dispatchedbtnbg {
    background: #bc04b7 !important;
    border-color: #bc04b7 !important;
    color: white !important;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
}

.deliveredbtnbg {
    background: #eb1d5d !important;
    border-color: #eb1d5d !important;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
}

.invoicedbtnbg {
    background: #00afec !important;
    border-color: #00afec !important;
    width: 110px;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
}

.paidbtnbg {
    background: #5ec096 !important;
    border-color: #5ec096 !important;
    cursor: auto !important;
    text-transform: uppercase;
    width: -webkit-fill-available;
    margin: 7px 30px;
}

.PayButton {
    background: #7bb902 !important;
    border: 0;
    color: #000 !important;
    border-radius: 2px;
}

.OrderPaymentDetails {
    margin-bottom: 35px;

    .CardDetailsSec {
        .inputdiv {
            position: relative;

            i.fa-solid,
            i.fa-regular {
                position: absolute;
                bottom: 15px;
                left: 10px;
            }

            label {
                color: #000;
                font-weight: 600;
            }

            input {
                border: 2px solid #5ec096;
                border-radius: 5px;
                background-color: #fff;
                padding: 10px;
                padding-left: 30px;

                &:focus {
                    box-shadow: none;
                    outline: 0;
                    border-color: #5ec096;
                }
            }
        }

        .SubmitBtn {
            color: #fff;
            background-color: #5ec096;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 500;
            border: 0;
            padding: 7px 25px;
            margin-top: 30px;
            margin-bottom: 65px;
        }
    }

    p.inputError {
        margin: 0;
        font-size: 12px;
    }
}

.paymentDetailHeading {
    text-align: center;
    width: 100%;
    padding: 6px;
}

@media (max-width: 768px) {
    .table__pagination__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px;
        margin-bottom: 7rem;

        ul {
            margin: 0;

            li {
                margin: 0;
                padding: 0;

                a {
                    padding: 2px 12px;
                }
            }
        }

        .pagination__select {
            margin-top: 1rem !important;
            margin-bottom: 1rem !important;

            .dropdwon_Option {
                font-size: xx-small;
            }
        }
    }

    .inprocessbtnbg {
        margin: 7px 15px;
    }

    .orders {
        margin-bottom: 20px;
    }

    .corporate_order_box {
        padding: 10px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {}