.footerli {
    color: black;
    text-decoration: none !important;
}

.footer-socialIcon {
    width: 38px;
    background: white;
    height: 32px;
}

.Footer {
    &__main {
        position: fixed;
        background: #315657;
        color: white;
        width: 100%;
        bottom: 0;
    }

    &__copyright {
        font-size: 18px;
        font-weight: 700;
    }

    &__li {
        list-style: none;
        cursor: pointer;
    }

    &__sGoogle:hover {
        background-color: #069f33;

        & > .footerli {
            color: white;
        }
    }

    .FooterContent {
        display: flex;
        justify-content: space-between;
        margin: 12px 0;

        .FooterDetails {
            .Footer__copyright {
                .footerCopyRight {
                    color: white;
                    list-style: none;
                    text-decoration: none;
                }
            }
        }

        ul {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 768px) {
    .Footer {
        .FooterContent {
            flex-direction: column;
            align-items: center;
            margin: 0;

            .FooterDetails {
                .Footer__copyright {
                    text-align: center;
                    font-size: 14px;
                    margin: 13px 0;
                }
            }

            .FooterSocialIcon {
                margin-bottom: 10px;
            }
        }
    }
}
