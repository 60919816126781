// $common: (
//     display: flex,
//     font-weight: 400,
//     padding-right: 7px,
// );
h5 {
    font-weight: revert;
    padding: 0px;
}

.Details_Section {
    display: flex;
    height: 200px;

    img.firstImage {
        // width: 20%;
        // width: 18%;
        // border-radius: 50%;
        // object-fit: fill;
        // margin-bottom: 30px;
        width: 20%;
        border-radius: 50%;
        margin: 9px 20px;
    }

    .detailRow {
        padding-left: 20px;
        .demo {
            padding: 0px;
        }
    }
}

.Main_head {
    background-color: #e6fceb;
    line-height: 40px;

    .modalTitle h2 {
        font-weight: 700;
        font-size: 25px;
    }
}

// #e6fceb
.Restaurants_Sestion {
    .RewardDetail {
        margin-bottom: 2rem;
        .table {
            tbody {
                tr {
                    td {
                        border: 1px solid #5ec096;
                        border-top: 0;
                        border-bottom: 0;
                    }

                    .rewardPic {
                        width: 8%;

                        img {
                            width: 100%;
                        }
                    }

                    &:nth-child(odd) {
                        background: #f9f9f9;
                    }

                    .vendor_Name {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// Qr code scss
.QR_Modal {
    .Modal_Section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .businessName {
            display: flex;
            font-weight: 400;

            justify-content: center;

            .firstSection {
                padding-right: 7px;
            }
        }

        .RewardSection {
            display: flex;
            font-weight: 400;

            justify-content: center;

            .first {
                padding-right: 7px;
            }
        }

        .QR_Section {
            // margin: auto;
            text-align: center;
        }
    }
}

.Print_QR_Section {
    display: flex;
    padding-top: 10px;
    justify-content: center;

    .printClass {
        background-color: #33333312;
        color: #827b7b;
        border: 1px solid;
        border-radius: 10%;
    }
}

@media (max-width: 768px) {
    .Details_Section {
        display: block;

        img.firstImage {
            width: 95%;
            object-fit: contain;
            margin-bottom: 35px;
        }

        .detailRow {
            // h
        }
    }

    .RewardDetail {
        margin-bottom: 2rem;

        table,
        thead,
        tr {
            text-align: center;
            font-size: 14px;
            width: max-content;
        }
    }
}

.printAble_Content {
    padding: 25px;
}
