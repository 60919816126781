.place-order-img {
    height: 50px;
}

.model {
    margin-top: 3%;
}

.modal-header {
    padding: 0.7rem 1rem;
}

.detail-para {
    margin-top: 2px;
}

.btn-close {
    font-weight: 400 !important;
    font-size: 20px;
    color: black !important;
}

.placeHeader {
    background-color: #e9fbe9;
}

.orderSec {
    line-height: 16px;
    font-weight: bold;
    color: black;
}

.selectbuybox {
    border: 1px solid #5ec096;
    margin-bottom: 6px;
    display: inline-block;
    width: 70%;
    text-align: center;
    border-radius: 10px;
}
.catergoryName {
    color: #666666;
}
.header {
    background-color: #e9fbe9;
    padding: 2px 15px;
    .ModalTitle {
        font-family: sans-serif;
        font-weight: 600;
    }
}

.profile-Click {
    color: blue;
    cursor: pointer;
    margin: 2px;
}

.selectQuantity .css-13cymwt-control {
    border-color: #5ec096;
}
