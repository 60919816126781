grid__item {
    float: left;
    width: 100%;
}
.herovander {
    padding: 20px 0;
    background-color: #ebebeb;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.modalHeader{
    background-color: #e9fbe9;
    padding: 3px 15px;
}
.AddresFiled{
    font-family: sans-serif;
    color: #333;
}
.mailEnvelop{
    color: grey!important;
}
.modalHeading{
    /* font-weight: 500; */
        font-weight: 800;
    font-family: sans-serif;
}
.hero_memberpage {
    background-image: url('../../../public/images/hero-corp.jpg');
}
.bgvenders {
    background-image: url('../../../public/images/slider-5.jpg');
}
minheights {
    min-height: 650px;
}
.topbannerformsvendors {
    margin-top: 7%;
}

.topbannerformsvendors .pagehead_title {
    background: #fff;
    padding: 5px 0;
    color: #000;
    font-size: 32px;
    letter-spacing: 2px;
    border: 2px solid #ebebeb;
    /* font-style: italic; */
}
.pagehead_title {
    font-size: 42px;
    line-height: 1.36364;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    padding: 180px 9%;
    text-align: center;
}
.icon {
    top: 6px;
    left: 15px;
    font-size: larger;
}
.btndisable{
    opacity: 0.5;
    cursor: not-allowed !important
}
.commonInput {
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    padding: 7px;
    font-size: 15px;
    margin: 0px;
    color: #000;
    font-weight: normal;
    padding-left: 40px;
}
.buttonDisable {
    opacity: 0.5;
    cursor: not-allowed !important;
}
.form-select {
    border: 1px solid #ccc !important;
    color: #000 !important;
    font-weight: normal;
    padding-left: 40px;
}

.submitbtn {
    font-size: 20px;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    transition: 0.1s all;
    -webkit-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -o-transition: 0.1s all;
    cursor: pointer;
    outline: none;
    padding: 3px 6px;
    width: 100%;
    border: none;
    background-color: #5ec096 !important;
    margin-bottom: 0.5em;
    border-radius: 6px;
}
.regisH6 {
    color: #666;
    font-weight: bold;
    font-size: 14px;
}

.SignInUp {
    border: none !important;
    background: transparent;
    color: #5ca3d9;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.loginforgot {
    border: none !important;
    background: transparent;
    color: #5ca3d9 !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 10px;
}
